import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

import ApiService from "./services/api.service";
import "./common/mixins";
import "./common/filters";

// Global Components
import "./libs";

// Extend

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// import feather icon form vue-form-wizard
require("@core/assets/fonts/feather/iconfont.css");

const configUrl = `${window.location.origin}/config.json`;

Vue.config.productionTip = false;

Vue.axios.get(configUrl).then(resp => {
  ApiService.init(resp.data.baseUrl);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});

import { LOGIN, LOGOUT, FETCH_USER } from "../constants/actions.type";
import { SET_USER } from "../constants/mutations.type";
import ApiService from "@/services/api.service";
import { storeToken, clearToken } from "@/services/token.service";
import router from "@/router";

export default {
  [LOGIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/token", payload)
        .then(resp => {
          storeToken(resp.jwtToken);
          ApiService.setHeader(resp.jwtToken);
          context.dispatch(FETCH_USER);
          // context.dispatch(FETCH_USER);
          resolve();
        })
        .catch(err => {
          return reject();
        });
    });
  },
  [LOGOUT](context) {
    router.push({ name: "login" });
    return new Promise((resolve, reject) => {
      ApiService.post("auth/revoketoken")
        .then(() => {
          context.commit(SET_USER, null);
          resolve();
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          clearToken();
          ApiService.removeHeader();
        });
    });
  },
  [FETCH_USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("me")
        .then(resp => {
          context.commit(SET_USER, resp);
          resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

import axios from "axios";
import ApiService from "./api.service";

const LOCAL_STORAGE_TOKEN = "token";

export const isAuthenticated = () => {
  return getToken() != null;
};

export const getNewToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("auth/refreshtoken")
      .then(response => {
        storeToken(response.jwtToken);
        resolve(response.jwtToken);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const storeToken = token => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
};

export const clearToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
};

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN);
};

export default {
  isAuthenticated,
  getNewToken,
  storeToken,
  clearToken,
  getToken
};

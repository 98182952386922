import Vue from "vue";
import VueRouter from "vue-router";
import routeConfig from "./routes";
import { isAuthenticated } from "@/services/token.service";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: routeConfig
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login") {
    if (!isAuthenticated()) router.push({ name: "login" });
  } else {
    next();
  }
  next();
});

export default router;

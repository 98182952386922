import Vue from "vue";
import { mapState } from "vuex";
import { CHANGE_LOADER } from "../store/constants/actions.type";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const generateToast = (messageType, message) => {
  let icon, title, variant;
  switch (messageType.toLowerCase()) {
    case "success":
      title = "Success";
      icon = "CheckIcon";
      variant = "success";
      break;
    case "info":
      title = "Information";
      icon = "InfoIcon";
      variant = "info";
      break;
    case "error":
      title = "We got problem ...";
      icon = "FrownIcon";
      variant = "danger";
      break;
    default:
      break;
  }

  Vue.$toast({
    component: ToastificationContent,
    props: {
      title: title,
      icon,
      text: message,
      variant
    }
  });
};

Vue.mixin({
  components: { ToastificationContent },
  data: () => {
    return {
      // showPopUp: false,
      // get defaultMonthDate() {
      //   return Vue.moment().startOf("day").format("MM");
      // },
      // get defaultFromDate() {
      //   return Vue.moment().utc().add(7, "H").startOf("day").subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss");
      // },
      // get defaultToDate() {
      //   return Vue.moment().utc().add(7, "H").startOf("day").format("YYYY-MM-DDTHH:mm:ss");
      // }
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.loader.isLoading
    })
  },
  methods: {
    changeLoader(value) {
      this.$store.dispatch(CHANGE_LOADER, value);
    },
    updateCollection(collection, keyCol, newRecord) {
      let idx = collection.findIndex(x => x[keyCol] == newRecord[keyCol]);
      collection[idx] = newRecord;
      return collection;
    },
    showToast(messageType, message) {
      generateToast(messageType, message);
    },
    async showConfirm(confirmMessage, confirmInput) {
      return new Promise((resolve, reject) => {
        if (confirmInput) {
          this.$swal({
            title: `Please type "${confirmInput}" to continue`,
            input: "text",
            inputPlaceholder: confirmMessage,
            icon: "question",
            showCancelButton: true,
            focusConfirm: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-lg btn-primary",
              cancelButton: "btn btn-lg btn-secondary ml-1"
            },

            inputValidator: value => {
              if (value !== confirmInput) {
                this.showToast("error", "Confirmation Text Not Matched.");
                resolve(false);
              }
            },
            buttonsStyling: false
          }).then(result => {
            resolve(result.isConfirmed);
          });
        } else {
          this.$swal({
            title: "Confirm Action ?",
            text: confirmMessage,
            icon: "question",
            showCancelButton: true,
            focusConfirm: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-lg btn-primary",
              cancelButton: "btn btn-lg btn-secondary ml-1"
            },
            buttonsStyling: false
          }).then(result => {
            resolve(result.isConfirmed);
          });
        }
      });
    },
    download(stream, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([stream]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }
});

export { generateToast };

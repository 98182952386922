import Vue from "vue";
import numeral from "numeral";

//Format Date Time
Vue.filter("dateTime", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD/MMM/YYYY HH:mm:ss");
});

//Format Date Only
Vue.filter("date", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD/MMM/YYYY");
});

//Format Date Only
Vue.filter("month", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("MMMM, YYYY");
});

//  CONVERT NUMBER
Vue.filter("number", function (value) {
  return numeral(value).format("0,0");
});

//  CONVERT DECIMAL
Vue.filter("decimal", function (value) {
  return numeral(value).format("0,0.00");
});

import axios from "axios";
import { getToken, getNewToken, clearToken } from "./token.service";
import router from "@/router";
import { generateToast } from "@/common/mixins";

export default {
  errorHandler(error) {
    if (!error.response) {
      generateToast("error", "Network Error. Please try again later.");
      return Promise.reject("Network Error.");
    }

    if (error.response.status !== 401) {
      generateToast("error", error.response.data.message);
      return Promise.reject(error.response.data.message);
    }

    if (error.config.url === "auth/refreshtoken") {
      this.clearAuth();
      generateToast("error", "Not Authorized. Please sign in.");
      return Promise.reject("Not Authorized. Please sign in.");
    }

    return getNewToken()
      .then(token => {
        // New request with new token
        const config = error.config;
        this.setHeader(token);
        config.headers.Authorization = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      })
      .catch(error => {
        Promise.reject(error);
      });
  },

  successHandler(response) {
    if (response.config.responseType === `blob`) {
      return Promise.resolve(response);
    } else if (response.data.status === "Success") {
      return Promise.resolve(response.data.result);
    } else {
      generateToast("error", response.data.message);
      return Promise.reject(response.data.message);
    }
  },

  init(baseUrl) {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    var token = getToken();
    if (token) this.setHeader(token);
    axios.interceptors.response.use(
      response => this.successHandler(response),
      error => this.errorHandler(error)
    );
  },

  setHeader(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  removeHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },

  clearAuth() {
    this.removeHeader();
    clearToken();
    router.push({ name: "login" });
  },

  get(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.get(resource);
  },

  post(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params);
  },

  put(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.put(resource, params);
  },

  delete(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.delete(resource);
  },

  patch(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.patch(resource, params);
  },

  download(resource) {
    return axios.get(resource, { responseType: "blob" });
  },

  downloadPost(resource, payload) {
    return axios.post(resource, payload, { responseType: "blob" });
  },

  upload(resource, formData) {
    var config = { headers: { "Content-Type": "multipart/form-data" } };
    return axios.post(resource, formData, config);
  },

  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  }
};

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard/Dashboard"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true
        }
      ]
    }
  },

  // MANAGEMENT
  {
    path: "/management/leads",
    name: "management-leads",
    component: () => import("@/views/Management/Leads"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Management",
          active: true
        },
        {
          text: "Leads",
          active: true
        }
      ]
    }
  },

  {
    path: "/management/scorecard",
    name: "management-score-card",
    component: () => import("@/views/Management/ScoreCard.vue"),
    meta: {
      pageTitle: "Score Card",
      breadcrumb: [
        {
          text: "Management",
          active: true
        },
        {
          text: "Score Card",
          active: true
        }
      ]
    }
  },

  {
    path: "/management/notification",
    name: "management-notification",
    component: () => import("@/views/Management/Notification.vue"),
    meta: {
      pageTitle: "Notification",
      breadcrumb: [
        {
          text: "Management",
          active: true
        },
        {
          text: "Notification",
          active: true
        }
      ]
    }
  },

  {
    path: "/management/useraccount",
    name: "management-sales-account",
    component: () => import("@/views/Management/SalesAccount.vue"),
    meta: {
      pageTitle: "User Account",
      breadcrumb: [
        {
          text: "Management",
          active: true
        },
        {
          text: "User Account",
          active: true
        }
      ]
    }
  },
  {
    path: "/management/useraccount/detail:salesCode?",
    name: "management-sales-account-detail",
    props: true,
    component: () => import("@/views/Management/SalesAccountDetail.vue"),
    meta: {
      pageTitle: "User Account Detail",
      breadcrumb: [
        {
          text: "Management",
          active: true
        },
        {
          text: "Sales Account",
          path: "/management/useraccount"
        },
        {
          text: "Detail",
          active: true
        }
      ]
    }
  },

  // CMS

  {
    path: "/cms/news/group",
    name: "cms-news-group",
    component: () => import("@/views/CMS/NewsGroup.vue"),
    meta: {
      pageTitle: "News Group",
      breadcrumb: [
        { text: "Parameter", active: true },
        { text: "News Group", active: true }
      ]
    }
  },
  {
    path: "/cms/news",
    name: "cms-news",
    component: () => import("@/views/CMS/News.vue"),
    meta: {
      pageTitle: "News",
      breadcrumb: [
        {
          text: "CMS",
          active: true
        },
        {
          text: "News",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/news/detail/:mode?/:newsCode?",
    name: "cms-news-detail",
    props: true,
    component: () => import("@/views/CMS/NewsDetail.vue"),
    meta: {
      pageTitle: "News",
      breadcrumb: [
        {
          text: "CMS",
          active: true
        },
        {
          text: "News",
          name: "cms-news-item"
        },
        {
          text: "Detail",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/learning/category",
    name: "cms-learning-category",
    component: () => import("@/views/CMS/LearningCategory.vue"),
    meta: {
      pageTitle: "Learning Group",
      breadcrumb: [
        { text: "CMS", active: true },
        {
          text: "Learning",
          active: true
        },
        {
          text: "Group",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/learning/",
    name: "cms-learning-item",
    component: () => import("@/views/CMS/Learning.vue"),
    meta: {
      pageTitle: "Learning",
      breadcrumb: [
        { text: "CMS", active: true },
        {
          text: "Learning",
          active: true
        },
        {
          text: "Item",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/learning/detail:learningCode?",
    name: "cms-learning-detail",
    props: true,
    component: () => import("@/views/CMS/LearningDetail.vue"),
    meta: {
      pageTitle: "Learning",
      breadcrumb: [
        { text: "CMS", active: true },
        {
          text: "Learning",
          active: true
        },
        {
          text: "Detail",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/flyer/detail:flyerCode?",
    name: "cms-flyer-detail",
    props: true,
    component: () => import("@/views/CMS/FlyerDetail.vue"),
    meta: {
      pageTitle: "Flyer",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Flyer",
          active: true
        },
        {
          text: "Detail",
          active: true
        }
      ]
    }
  },
  {
    path: "/cms/flyer/",
    name: "cms-flyer-item",
    component: () => import("@/views/CMS/Flyer.vue"),
    meta: {
      pageTitle: "Flyer",
      breadcrumb: [
        { text: "CMS", active: true },
        {
          text: "Flyer",
          active: true
        },
        {
          text: "Item",
          active: true
        }
      ]
    }
  },

  // PARAMETER

  {
    path: "/parameter/leavetype",
    name: "parameter-leave-type",
    component: () => import("@/views/Parameter/LeaveType.vue"),
    meta: {
      pageTitle: "Leave Type",
      breadcrumb: [
        { text: "Parameter", active: true },
        { text: "Leave Type", active: true }
      ]
    }
  },
  {
    path: "/parameter/activityresult",
    name: "parameter-activity-result",
    component: () => import("@/views/Parameter/ActivityResultType"),
    meta: {
      pageTitle: "Activity Result",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Activity Result",
          active: true
        }
      ]
    }
  },
  {
    path: "/parameter/activitytype",
    name: "parameter-activity-type",
    component: () => import("@/views/Parameter/ActivityType.vue"),
    meta: {
      pageTitle: "Activity Type",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Activity Type",
          active: true
        }
      ]
    }
  },
  {
    path: "/parameter/productcategory",
    name: "parameter-product-category",
    component: () => import("@/views/Parameter/ProductCategory.vue"),
    meta: {
      pageTitle: "Product Category",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Product",
          active: true
        },
        {
          text: "Category",
          active: true
        }
      ]
    }
  },
  {
    path: "/parameter/product",
    name: "parameter-product",
    component: () => import("@/views/Parameter/Product.vue"),
    meta: {
      pageTitle: "Product",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Product",
          active: true
        },
        {
          text: "Item",
          active: true
        }
      ]
    }
  },
  {
    path: "/parameter/leads",
    name: "parameter-leads",
    component: () => import("@/views/Parameter/LeadsType.vue"),
    meta: {
      pageTitle: "Leads Type",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Leads",
          active: true
        }
      ]
    }
  },
  {
    path: "/parameter/program",
    name: "parameter-program",
    component: () => import("@/views/Parameter/Program.vue"),
    meta: {
      pageTitle: "Program",
      breadcrumb: [
        { text: "Parameter", active: true },
        {
          text: "Program",
          active: true
        }
      ]
    }
  },

  // CONFIGURATION
  {
    path: "/configuration/system",
    name: "config-system",
    component: () => import("@/views/Configuration/System"),
    meta: {
      pageTitle: "System",
      breadcrumb: [
        { text: "Configuration", active: true },
        {
          text: "System",
          active: true
        }
      ]
    }
  },
  {
    path: "/configuration/adminaccount",
    name: "config-admin-account",
    component: () => import("@/views/Configuration/AdminAccount.vue"),
    meta: {
      pageTitle: "Admin Account",
      breadcrumb: [
        { text: "Configuration", active: true },
        {
          text: "Admin Account",
          active: true
        }
      ]
    }
  },

  // REPORT
  {
    path: "/report/leads/plain",
    name: "report-leads-plain",
    component: () => import("@/views/Report/PlainLeads"),
    meta: {
      pageTitle: "Plain Leads Report",
      breadcrumb: [
        { text: "Report", active: true },
        {
          text: "Plain Leads",
          active: true
        }
      ]
    }
  },
  {
    path: "/report/attendance",
    name: "report-attendance",
    component: () => import("@/views/Report/Attendance"),
    meta: {
      pageTitle: "Attendance Report",
      breadcrumb: [
        { text: "Report", active: true },
        {
          text: "Attendance",
          active: true
        }
      ]
    }
  },
  {
    path: "/report/leave",
    name: "report-leave",
    component: () => import("@/views/Report/Leave"),
    meta: {
      pageTitle: "Leave Report",
      breadcrumb: [
        { text: "Report", active: true },
        {
          text: "Leave",
          active: true
        }
      ]
    }
  },

  // AUTH

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error/Error404.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

export default routes;

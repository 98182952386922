import { CHANGE_LOADER } from "../constants/actions.type";
import { SET_LOADER } from "../constants/mutations.type";

export default {
  [CHANGE_LOADER](context, payload) {
    return new Promise(resolve => {
      context.commit(SET_LOADER, payload);
      resolve();
    });
  }
};
